import React, { useEffect, useState } from "react";
import logo from "../Logo_T.png";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import AuthUser from "./AuthUser";
import { APIURL } from "../constant";
import { toast } from "react-toastify";
import axios from "axios";

const ChangePassword = () => {
  const userName = localStorage.getItem("userName");

  const [email, setEmail] = useState(userName?.replace(/"/g, ""));
  const [password, setPassword] = useState();
  const [toastDisplayed, setToastDisplayed] = useState(false);
  const checkToken = localStorage.getItem("resetpasswordtoken");
  const [errors, setErrors] = useState({});
  const [Checkpass, setCheckpass] = useState(false);
  const [faillogin, setfaillogin] = useState("");
  const [failstatus, setfailstatus] = useState("");

  const navigate = useNavigate();

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const numericRegex = /\d/;

    if (password == "") {
      newErrors.password = "Password is required";
      valid = false;
    } else if (password?.length < 8) {
      newErrors.password = "Password must be at least 8 characters long";
      valid = false;
    } else if (!specialCharacterRegex.test(password)) {
      newErrors.password =
        "Password must contain at least one special character";
      valid = false;
    } else if (!numericRegex.test(password)) {
      newErrors.password = "Password must contain at least one numeric digit";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  useEffect(() => {
    document.addEventListener('contextmenu', (event) => {
      event.preventDefault();
    });

    return () => {
      document.removeEventListener('contextmenu', (event) => {
        event.preventDefault();
      });
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      // Prevent default behavior for specific key combinations
      if (e.ctrlKey && e.shiftKey && e.key === 'I') {
        e.preventDefault();
        console.log('Ctrl + Shift + I is disabled');
      }

      if (e.ctrlKey && e.keyCode === 85) {
        e.preventDefault();
        console.log('Ctrl + U is disabled');
      }

      // if (e.ctrlKey && (e.keyCode === 67 || e.keyCode === 86 || e.keyCode === 85 || e.keyCode === 117)) {
      //   e.preventDefault();
      //   console.log('Ctrl + C, Ctrl + V, Ctrl + U, and Ctrl + F6 are disabled');
      // }
    };

    const handleKeyPress = (e) => {
      // Prevent default behavior for Ctrl + U
      if (e.ctrlKey && e.key === 'u') {
        e.preventDefault();
        console.log('Ctrl + U is disabled');
      }
    };

    // Attach event listeners
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keypress', handleKeyPress);

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, []);

  const HandleEmail = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setfaillogin("");
    if (
      (name === "email" &&
        (value.includes("$") ||
          value.includes("`") ||
          value.includes("|") ||
          value.includes(" ") ||
          value.includes("~") ||
          value.includes(":") ||
          value.includes(",") ||
          value.includes(">") ||
          value.includes("<") ||
          value.includes("(") ||
          value.includes(")") ||
          value.includes("*") ||
          value.includes("&") ||
          value.includes("%") ||
          value.includes("#") ||
          value.includes("+") ||
          value.includes("?") ||
          value.includes("!") ||
          value.includes(";"))) ||
      value.includes("=") ||
      value.includes('"') ||
      value.includes(`'`) ||
      value.includes("/") ||
      value.includes("}") ||
      value.includes("{") ||
      value.includes("^") ||
      value.includes("\\") ||
      value.includes("]") ||
      value.includes("[")
    ) {
      return setErrors({
        email: `Not valid Key`,
      });
    } else {
      setErrors({
        email: ``,
      });
    }

    setEmail(e.target.value);
  };

  const HandlePassowrd = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    const passwordValue = e.target.value.trim();

    setfaillogin("");

    if (name === "password" && value.includes(" ")) {
      const errmesg = value.slice(-1);
      const errmesglast = errmesg[errmesg.length - 1];

      return setErrors({
        password: `Space is not valid Key`,
      });
    } else {
      setErrors({
        password: ``,
      });
    }

    setPassword(passwordValue);
  };

  const submitHandleForm = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      await axios
        .post(APIURL + "User/ResetPassword", {
          UserName: userName ? userName?.replace(/"/g, "") : email,
          Password: password,
          Token: lastUrlPart,
        })
        .then((res) => {
          console.log("asas", res);
          if (res.data.responseCode == "200") {
            setToastDisplayed(true);
            // if(toastDisplayed == true){
            toast.success(res.data.responseMessage);
            // }
            setTimeout(() => {
              navigate("/");
            }, 1200);
          } else {
            setToastDisplayed(false);
            setfaillogin(res.data.responseMessage);
            setfailstatus(res.data.responseStatus);
            // if(toastDisplayed == true){

            toast.warning(res.data.responseMessage);
            // }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const currentUrl = window.location.href;
  const urlParts = currentUrl.split("/");
  const lastUrlPart = urlParts[urlParts.length - 1];

  console.log("lastUrlPart", lastUrlPart);

  useEffect(() => {
    if (toastDisplayed) {
      setTimeout(() => {
        setToastDisplayed(false);
      }, 1500);
    }
  }, [toastDisplayed]);

  // useEffect(() => {
  //   const resetpasswordToken = localStorage.getItem("resetpasswordtoken");
  //   if (resetpasswordToken !== lastUrlPart) {
  //     setTimeout(() => {
  //       navigate("/");
  //     }, 2000);
  //   }
  // }, []);

  return (
    <>
      <Helmet>
        {" "}
        <title>Reset Password</title>{" "}
      </Helmet>

      {/* {checkToken !== lastUrlPart ? (
        <div className="login_outer ">
          <div className="login_inner login-max-width">
            <div className="login_inner_header">
              <Link to="/">
                <img src={logo} />{" "}
              </Link>
              <h3>Document Management System</h3>
            </div>

            <div className="login_form ">
              <h5>Reset Password</h5>
            </div>
            <div className="login_form_panel">
              <div className="form-bx mb-5">
                <h2 className="font-sizeasas">
                  Token Expired Please Try Again
                </h2>
              </div>
            </div>
          </div>
        </div>
      ) : ( */}
        <div className="login_outer ">
          <div className="login_inner login-max-width">
            <div className="login_inner_header">
              <Link to="/">
                <img src={logo} />{" "}
              </Link>
              <h3>Document Management System</h3>
            </div>

            <div className="login_form ">
              <h5>Reset Password</h5>
            </div>

            <div className="login_form_panel">
              <div className="form-bx mb-5">
                <label>
                  <input
                    type="email"
                    value={userName.replace(/"/g, "")}
                    disabled
                    className="form-control"
                    placeholder="Username"
                    onChange={(e) => HandleEmail(e)}
                    id="email"
                  />

                  <span className="sspan"></span>
                </label>
                {errors?.email ? (
                  <small className="errormsg">{errors?.email}</small>
                ) : faillogin ? (
                  <small className="errormsg">{faillogin}</small>
                ) : (
                  ""
                )}
              </div>
              <div className="form-bx">
                <label>
                  <input
                    type={Checkpass === true ? "text" : "password"}
                    className="form-control"
                    placeholder="Enter password"
                    onChange={(e) => {
                      HandlePassowrd(e);
                    }}
                    id="pwd"
                  />
                  <span className="sspan"></span>
                </label>
                {errors?.password ? (
                  <small className="errormsg2">{errors?.password}</small>
                ) : (
                  ""
                )}
                {Checkpass ? (
                  <i
                    className="bi bi-eye passwordcheck"
                    onClick={() => {
                      setCheckpass(!Checkpass);
                    }}
                  ></i>
                ) : (
                  <i
                    className="bi bi-eye-slash passwordcheck"
                    onClick={() => {
                      setCheckpass(!Checkpass);
                    }}
                  ></i>
                )}
              </div>

              <div className="form-footer">
                {/* <Link to='/' className='register'>Back</Link> */}
                <button
                  className="login"
                  type="button"
                  onClick={(e) => submitHandleForm(e)}
                  disabled={toastDisplayed}
                >
                  {toastDisplayed == true ? "Please Wait.." : "Submit"}
                </button>
              </div>
            </div>
          </div>
        </div>
      {/* )} */}
    </>
  );
};

export default ChangePassword;
