import React, { useEffect, useState } from "react";
import { Link,useNavigate } from "react-router-dom";
import DashboardLayout from "../components/DashboardLayout";
import FINDashboardTable from "../tables/FINDashboardTable";
import { Helmet } from "react-helmet";
import { APIURL } from "../constant";
import axios from "axios";
import { Storage } from "../login/Storagesetting";

const ImportDashboard = () => {
  const navigate = useNavigate();
  const handleAdvanceSearch = () => {
    navigate("/AdvanceSearchFIN");
  }
  const menuname = sessionStorage.getItem("menuname");
  const submenuname = sessionStorage.getItem("submenuname");

  const [data, setData] = useState([]);

  const RoleId = Storage.getItem("roleIDs");
  const useId = Storage.getItem("userID");

    // ----- Start Code For Geting Table List Data
    const handleData = async () => {
      await axios
        .post(APIURL + "FINApplication/GetActingDashboardDataFIN", {
          DepartmentID: "4",
          UserID: useId.replace(/"/g, ""),
          RoleID: RoleId,
        })
        .then((res) => { 
          if (res.data.responseCode === "200") {
            setData(res.data.responseData);
          } else {
            setData([]);
          }
        });
    };
  
    useEffect(() => {
      handleData();
    }, []);

    const clickhandlerole = () => {
      sessionStorage.setItem("submenuname", "Acting Role");
    };
  
  return (
    <>
      <Helmet>
        <title>Foreign Investments Dashboard</title>
      </Helmet>
      <DashboardLayout>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="">Foreign Investment</Link>
            </li>
            <li className="breadcrumb-item active">Dashboard</li>
          </ol>
        </nav>

        <section className="section dashboard">
          <div className="row">
            <div className="col-md-12">
              <div className="datatable">
                <h4 className="section_top_heading">SUBMITTED REQUESTS

                <div style={{display:"flex", columnGap:"10px"}}>
                {(RoleId == "6" || RoleId == "7" || RoleId == "8") &&
                  data?.length ? (
                    <Link
                      to="/FinActingRoleDashboard"
                      className="act_btn-dashboard"
                      onClick={clickhandlerole}
                    >
                      Acting Role <span>{data?.length}</span>
                    </Link>
                  ) : (
                    ""
                  )}
                <button
                    type="button"
                    className="adSearchButton"
                    onClick={() => handleAdvanceSearch()}
                  >
                    Advanced Search
                  </button>
                  </div>
                </h4>
                <FINDashboardTable />
              </div>
            </div>
          </div>
        </section>
      </DashboardLayout>
    </>
  );
};

export default ImportDashboard;
