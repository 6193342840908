import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DashboardLayout from "../components/DashboardLayout";
import INSDashboardTable from "../tables/INSDashboardTable";
import { Helmet } from "react-helmet";
import { Storage } from "../login/Storagesetting";
import { APIURL } from "../constant";
import axios from "axios";

const INSDashboard = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const handleAdvanceSearch = () => {
    navigate("/AdvanceSearchINS");
  };
  const menuname = sessionStorage.getItem("menuname");
  const submenuname = sessionStorage.getItem("submenuname");

  const RoleId = Storage.getItem("roleIDs");
  const useId = Storage.getItem("userID");

  const handleData = async () => {
    await axios
      .post(APIURL + "FINApplication/GetActingDashboardDataFIN", {
        DepartmentID: "4",
        UserID: useId.replace(/"/g, ""),
        RoleID: RoleId,
      })
      .then((res) => { 
        if (res.data.responseCode === "200") {
          setData(res.data.responseData);
        } else {
          setData([]);
        }
      });
  };

  useEffect(() => {
    handleData();
  }, []);

  const clickhandlerole = () => {
    sessionStorage.setItem("submenuname", "Acting Role");
  };

  return (
    <>
      <Helmet>
        <title>
          {menuname ? menuname : ""} {submenuname ? submenuname : ""}
        </title>
      </Helmet>
      <DashboardLayout>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="">Inspectorate</Link>
            </li>
            <li className="breadcrumb-item active">Dashboard</li>
          </ol>
        </nav>

        <section className="section dashboard">
          <div className="row">
            <div className="col-md-12">
              <div className="datatable">
                <h4 className="section_top_heading">
                  SUBMITTED REQUESTS

                  <div style={{display:"flex", columnGap:"10px"}}>
                {(RoleId == "6" || RoleId == "7" || RoleId == "8") &&
                  data?.length ? (
                    <Link
                      to="/InsActingRoleDashboard"
                      className="act_btn-dashboard"
                      onClick={clickhandlerole}
                    >
                      Acting Role <span>{data?.length}</span>
                    </Link>
                  ) : (
                    ""
                  )}

                  <button
                    type="button"
                    className="adSearchButton"
                    onClick={() => handleAdvanceSearch()}
                  >
                    Advanced Search
                  </button>
                  </div>
                </h4>
                <INSDashboardTable />
              </div>
            </div>
          </div>
        </section>
      </DashboardLayout>
    </>
  );
};

export default INSDashboard;
