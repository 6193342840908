import React, { useState, useRef, useEffect } from "react";
import DataTable from "react-data-table-component";
import { CSVLink } from "react-csv";
import * as FileSaver from "file-saver";
import axios from "axios";
import * as XLSX from "xlsx";
import ApplicationTypeForm from "../components/ApplicationTypeForm";
import { Link } from "react-router-dom";
import { APIURL } from "../../constant";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Storage } from "../../login/Storagesetting";
import { toast } from "react-toastify";
import moment from "moment/moment";

const ApplicationRollBackTable = () => {
  const SearchInputRef = useRef(null);
  const [SearchInput, setSearchInput] = useState("");
  const [SearchData, setSearchData] = useState([]);
  const [NoSearchData, setNoSearchData] = useState("");
  const [loading, setLoading] = useState(false);
  const [toastDisplayed, setToastDisplayed] = useState(false);

  const [deptSelectVal, setDeptSelectVal] = useState({
    deptId: "",
  });

  const [updateerr, setupdateerr] = useState();
  const [errors, setErrors] = useState({});
  const userID = Storage.getItem("userID");
  const csvLinkRef = useRef();

  const handleClick = (title) => {
    alert(`Title: ${title}`);
  };

  const [departmentData, setDepartmentData] = useState([]);

  const departmentApi = async () => {
    try {
      const responce = await fetch(APIURL + "Master/GetDepartmentData", {
        method: "Post",
        headers: {
          "content-type": "application/json",
        },
      });
      const data = await responce.json();
      setDepartmentData(data.responseData);
    } catch (error) {
      console.log("Fetch Data", error);
    }
  };

  // applicationtype update start

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const UpdateModalClose = () => {
    setShowUpdateModal(false);
    setUpdateData({
      name: "",
    });
    setupdateerr("");
  };
  const [updateData, setUpdateData] = useState({
    name: "",
  });

  const GetSearchData = async () => {
    if (validateSearch()) {
      try {
        setLoading(true);
        let getData = await axios.post(APIURL + "Admin/GetApplicationRecord", {
          RBZReferenceNumber: SearchInput,
          DepartmentID: deptSelectVal?.deptId,
        });

        if (getData?.data.responseCode == 200) {
          setSearchData(getData?.data?.responseData);
          setLoading(false);
        } else if (getData?.data.responseCode == 401) {
          setLoading(false);
          setSearchData([]);
          setNoSearchData(getData?.data.responseMessage);
        }
      } catch (error) {
        setLoading(false);
        console.log("Search Error - ", error);
      }
    } else {
      toast.warning("Please select department");
    }
  };

  const changeHandelFormValidate = (e) => {
    const { name, value } = e.target;
    const specialChars = /[!@#$%^&*(),.?":{}|<>`~]/;
    let newErrors = {};
    let valid = true;
    if (name == "SearchInput" && specialChars.test(value)) {
      newErrors.SearchInput = "Special characters not allowed";
      valid = false;
    } else if (name == "SearchInput" && value == " ") {
      newErrors.SearchInput = "First character cannot be a blank space";
      valid = false;
    } else {
      setSearchInput(value);
    }
    setErrors(newErrors);

    setSearchInput(value);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      GetSearchData();
    }
  };
  const handleClear = () => {
    setErrors({});
    setShowUpdateModal(false);
    setLoading(false);
    setNoSearchData("");
    if (SearchInputRef.current) SearchInputRef.current.value = "";
    setSearchData([]);
    setSearchInput("");
  };
  const validateSearch = () => {
    let valid = true;
    const newErrors = {};
    if (SearchInput.trim().length < 16) {
      newErrors.SearchInput = "RBZ reference number allow minimum 16 numbers";
      valid = false;
    }
    if (deptSelectVal.deptId == "") {
      valid = false;
    }
    // else if (SearchInput.trim().length > 6) {
    //   newErrors.SearchInput = "RBZ reference number allow maximum 6 numbers";
    //   valid = false;
    // }
    setErrors(newErrors);
    return valid;
  };

  const changeDeptValue = (e) => {
    const { name, value } = e.target;
    setDeptSelectVal((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const changeUpdateForm = (e) => {
    const { name, value } = e.target;
    // let newErrors = {};
    // const specialCharsOLD = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    // const spaceCheck = /\s{2,}/g;
    // // const specialChars = /[^\w\s&,_%-]/;
    // const specialChars = /[^\w\s&,-]/;
    // if (name == "name" && specialChars.test(value)) {
    //     newErrors.name = "Special characters not allowed.";
    // } else if (name == "name" && value.charAt(0) === ' ') {
    //     newErrors.name = "First character cannot be a blank space.";
    // } else if (name == "name" && spaceCheck.test(value)) {
    //     newErrors.name = "Multiple space not allow.";
    // } else if (name == "name" && (specialCharsOLD.test(value.charAt(0)))) {
    //     newErrors.name = "First special character is not allowed.";
    // }
    // else {

    //     setUpdateData((prevState) => ({
    //         ...prevState,
    //         [name]: value

    //     }));

    // }
    // setupdateerr(newErrors);
    setUpdateData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [updateID, setUpdateID] = useState("");
  const [updateRbzNum, setUpdateRbzNum] = useState("");
  const handleUpdate = async (rbzNum, id) => {
    setUpdateRbzNum(rbzNum);
    setShowUpdateModal(true);
    setUpdateID(id);
  };

  const handleUpdateData = async () => {
    setToastDisplayed(true);
    const updateValue = {
      ApplicationID: updateID,
      Description: updateData.name,
      UserID: userID.replace(/"/g, ""),
      DepartmentID: deptSelectVal.deptId,
    };

    try {
      const response = await fetch(APIURL + "Admin/ApplicationRoleBack", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updateValue),
      });
      const data = await response.json();
      setToastDisplayed(true);
      if (data.responseCode === "200") {
        toast.success(data.responseMessage, { autoClose: 2000 });
        setTimeout(() => {
          UpdateModalClose();
          setUpdateData({
            name: "",
          });
          setSearchInput("");

          setDeptSelectVal({
            deptId: "",
          });
          GetSearchData();
          setToastDisplayed(false);
        }, 2500);
      } else {
        toast.warning(data.responseMessage, { autoClose: 2000 });
        setTimeout(() => {
          setToastDisplayed(false);
        }, 2500);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // applicationtype update end

  useEffect(() => {
    departmentApi();
  }, []);

  const columns = [
    {
      name: "RBZ Reference Number",
      selector: (row) => row.rbzReferenceNumber,
      sortable: true,
      searchable: true,
      width: "20%",
    },
    {
      name: "Applicant Name",
      selector: (row) =>
        row.applicantType === 1 ? (
          <span>
            <i className="bi bi-c-circle-fill text-primary"></i>
            &nbsp;&nbsp;{row.companyName}
          </span>
        ) : row.applicantType === 2 ? (
          <span>
            <i className="bi bi-person-circle"></i>
            &nbsp;&nbsp;{row.name}
          </span>
        ) : row.applicantType === 3 ? (
          <span>
            <i class="bi bi-bank"></i>
            &nbsp;&nbsp;{row.governmentAgencyName}
          </span>
        ) : (
          ""
        ),
      sortable: true,
      searchable: true,
      width: "20%",
    },
    {
      name: "Amount",
      selector: (row) => row.amountValue,
      sortable: true,
      searchable: true,
      width: "15%",
    },
    {
      name: "Status",
      selector: (row) => row.statusName,
      sortable: true,
      searchable: true,
      width: "15%",
      // cell: row => <span>{row.status === 1 ? <span className="badge rounded-pill bg-success">Active</span> : <span className="badge rounded-pill bg-warning text-dark">Inactive</span>}</span>,
    },
    {
      name: "Response Date",
      selector: (row) => moment(row.responseDate).format("DD MMM YYYY"),
      sortable: true,
      searchable: true,
      width: "15%",
    },
    {
      name: "Action",
      width: "15%",
      cell: (row) => (
        <>
          {" "}
          <Link
            to=""
            className="me-2"
            onClick={() => handleUpdate(row.rbzReferenceNumber, row.id)}
          >
            {/* <i className="bi bi-pencil-square"></i> */}
            <span className="badge rounded-pill btn btn-primary rollbackbtn">
              {" "}
              Rollback
            </span>
          </Link>
        </>
      ),
    },
  ];
 

  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <div className="form-bx rollbackdrop">
          {/* <p className="form-label">Select Department</p> */}
          <label>
            <select
              name="deptId"
              aria-label="Large select example"
              onChange={(e) => {
                changeDeptValue(e);
              }}
              value={deptSelectVal.deptId}
            >
              <option value="">Select Department</option>
              {departmentData?.map((item) => {
                return <option value={item?.id}>{item?.departmentName}</option>;
              })}
            </select>
            <span className="sspan"></span>
          </label>
          {/* {errors === true && !updateData.name ? <small className="errormsg">Name is Required</small> : ''} */}
        </div>

        <div className="searchtable_inner_bx m-0 w-100">
          <input
            ref={SearchInputRef}
            type="text"
            name="SearchInput"
            placeholder="Search RBZ Reference Number"
            className={errors.SearchInput ? "error" : ""}
            value={SearchInput.trim()}
            onKeyDown={handleKeyDown}
            onChange={(e) => changeHandelFormValidate(e)}
          />
          <span className="sspan"></span>
          {errors.SearchInput ? (
            <small className="errormsg">{errors.SearchInput}</small>
          ) : (
            ""
          )}
          <button
            type="button"
            className="searchButton"
            disabled={SearchInput ? false : true}
            onClick={() => GetSearchData()}
            onKeyDown={handleKeyDown}
          >
            Search
          </button>
          <button
            type="button"
            className="resetButton"
            onClick={() => handleClear()}
          >
            Reset
          </button>
        </div>
      </div>
      <hr />
      <>
        {loading == true ? (
          <label className="outerloader2">
            <span className="loader"></span>
            <span className="loaderwait">Please Wait...</span>
          </label>
        ) : NoSearchData && !SearchData.length ? (
          <div className="row">
            <div className="col-12 text-center">
              <p>{NoSearchData}</p>
            </div>
          </div>
        ) : SearchData.length ? (
          <DataTable
            columns={columns}
            data={SearchData}
            persistTableHead={true}
            defaultSortFieldId={1}
            defaultSortAsc={true}
            // pagination
            // paginationRowsPerPageOptions={[10, 50, 100]}
            highlightOnHover
            dense
            striped
            fixedHeader
            // subHeader
            // subHeaderComponent={
            //     <>
            //         <div className="admintablesearch">
            //             <div className="tablesearch_bx">
            //                 <input
            //                     type="text"
            //                     placeholder="Search"
            //                     value={searchText}
            //                     onChange={(e) => setSearchText(e.target.value)}
            //                 />
            //             </div>

            //         </div>

            //     </>
            // }
          />
        ) : (
          <div className="row">
            <div className="col-12 text-center">
              <p>No records to show</p>
            </div>
          </div>
        )}
      </>

      {/* Application Form modal */}

      {/* Application Form update modal */}
      <Modal show={showUpdateModal} onHide={UpdateModalClose} backdrop="static">
        <div className="application-box editmodal-change">
          <div className="login_inner">
            <div className="login_form ">
              <h5>
                <Modal.Header closeButton className="p-0">
                  <Modal.Title>RollBack -- {updateRbzNum}</Modal.Title>
                </Modal.Header>
              </h5>
            </div>
            <div className="login_form_panel rollBackField">
              <Modal.Body className="p-0">
                <div className="form-bx mb-4">
                  <p className="form-label">Comments</p>
                  <label>
                    <textarea
                      name="name"
                      rows="4"
                      cols="50"
                      className="fomcontrol"
                      placeholder="Comments Here"
                      onChange={(e) => {
                        changeUpdateForm(e);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "_") {
                          e.preventDefault();
                        }
                      }}
                    >
                      {updateData?.name}
                    </textarea>
                    {/* <input type="text" name="name" value={updateData?.name} className='fomcontrol text-capitalize' placeholder="Application Category Name" onChange={(e) => { changeUpdateForm(e) }} onKeyDown={(e) => {
                                            if (e.key === "_") {
                                                e.preventDefault();
                                            }
                                        }} required /> */}
                    <span className="sspan"></span>
                  </label>
                  {updateerr?.name ? (
                    <span className="errormsg">{updateerr?.name}</span>
                  ) : (
                    ""
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer className="p-0 justify-content-between">
                <Button variant="secondary" onClick={UpdateModalClose}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={handleUpdateData}
                  disabled={toastDisplayed ? true : false}
                >
                  {toastDisplayed ? "Please wait" : "Submit"}
                </Button>
              </Modal.Footer>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ApplicationRollBackTable;
