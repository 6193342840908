import React, { useState, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axios from "axios";
import { FilterMatchMode, FilterService } from "primereact/api";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { InputText } from "primereact/inputtext";
import "primeicons/primeicons.css";
import moment from "moment";
import { APIURL } from "../constant";
import { Storage } from "../login/Storagesetting";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import ExportDashboardViewDetails from "../components/ExportDashboardViewDetails";
import ExportDashboardEditDetails from "../components/ExportDashboardEditDetails";
import { TailSpin } from "react-loader-spinner";

const INSAdvanceSearchTable = ({ advanceSearchData }) => {
  const useId = Storage.getItem("userID");
  const roleID = Storage.getItem("roleIDs");
  const roleName = Storage.getItem("roleName");

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    companyName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    bankName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    tinNumber: { value: null, matchMode: FilterMatchMode.IN },
  });


  const applicantName = (rowData) => {
    return (
      <span>
      {rowData.applicantType === 1 || rowData.userTypeID === 1 ? (
          <span>
            <i className="bi bi-c-circle-fill text-primary"></i>
            &nbsp;&nbsp;{rowData.applicantName}
          </span>
        ) : rowData.applicantType === 2 || rowData.userTypeID == 2 ? (
          <span>
            <i className="bi bi-person-circle"></i>
            &nbsp;&nbsp;{rowData.applicantName}
          </span>
        ) : rowData.applicantType === 3 || rowData.userTypeID == 3 ? (
          <span>
            <i class="bi bi-bank"></i>
            &nbsp;&nbsp;{rowData.governmentAgencyName}
          </span>
        ) : (
          ""
        )}
      </span>
    );
  };
  const typeOfImp = (rowData) => {
    return (
      <span>
        {rowData.applicantTypeName ? rowData.applicantTypeName : "-"}
      </span>
    );
  };

  const submittedDate = (rowData) => {
    return (
      <span>
        {moment(rowData.submittedDate).format("DD MMM YYYY")}
      </span>
    );
  };
  const statusDate = (rowData) => {
    return (
      <span>
        {rowData.statusName ? rowData.statusName : "-"}
      </span>
    );
  };
  const receivedDate = (rowData) => {
    return (
      <span>
        {moment(rowData.createdDate).format("DD MMM YYYY")}
      </span>
    );
  };

  const exportExcel = () => {
    import('xlsx').then((xlsx) => {
      const customHeaders = [
        { header: 'Applicant Name', key: 'applicantName' },
        { header: 'RBZ Reference Number', key: 'rbzReferenceNumber' },
        { header: 'Bank Name', key: 'bankName' },
        { header: 'Type of Exporter', key: 'applicantTypeName' },
        { header: 'Application Type', key: 'applicationName' },
        { header: 'Amount', key: 'amount' },
        { header: 'Currency', key: 'currencyCode' },
        { header: 'USD', key: 'usdEquivalent' },
        // { header: 'Sector', key: 'sectorName' },
        { header: 'Status', key: 'statusName' },
        { header: 'Created Date', key: 'createdDate' },
        { header: 'Submitted Date', key: 'submittedDate' },
      ];


      const dataWithCustomHeaders = advanceSearchData.map(item => {
        const newItem = {};
        customHeaders.forEach(header => {
          newItem[header.header] = item[header.key];
        });
        return newItem;
      });
      
      console.log("advanceSearchData", advanceSearchData)
      console.log("dataWithCustomHeaders", dataWithCustomHeaders)

      const worksheet = xlsx.utils.json_to_sheet(dataWithCustomHeaders);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      saveAsExcelFile(excelBuffer, 'Inspectorate_');
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
          type: EXCEL_TYPE
        });
        module.default.saveAs(data, fileName + new Date().getTime() + EXCEL_EXTENSION);
      }
    });
  };
  useEffect(() => {

  }, [advanceSearchData]);

  return (
    <>

      <div>
        <div class="form-footer my-3">
          <button type="button" icon="pi pi-file-excel" class="login tableExcel" onClick={exportExcel} data-pr-tooltip="XLS">Export</button>
        </div>
        <DataTable
          value={advanceSearchData}
          scrollable
          scrollHeight="650px"

          paginator={advanceSearchData.length > 10 ? true : false}
          filters={filters}
          selectionMode="checkbox"
          paginatorPosition={"both"}
          paginatorLeft
          rows={10}
          dataKey="id"
          rowHover
          rowsPerPageOptions={[10, 50, 100]}
          globalFilterFields={[
            "rbzReferenceNumber",
            "name",
            "companyName",
            "applicationType",
            "amount",
            "statusName",
          ]}
          emptyMessage="No Data found."

        >

          <Column
            field="applicantName"
            header="Applicant Name"
            sortable
            style={{ width: "220px" }}
            body={applicantName}
          ></Column>
          <Column
            field="rbzReferenceNumber"
            header="RBZ Reference Number"
            sortable
            style={{ width: "220px" }}
          ></Column>
          <Column
            field="bankName"
            header="Bank Name"
            sortable
            style={{ width: "220px" }}

          ></Column>
          <Column
            field="applicantTypeName"
            header="Type of Exporter"
            sortable
            style={{ width: "220px" }}
            body={typeOfImp}
          ></Column>
          <Column
            field="applicationName"
            header="Application Type"
            sortable
            style={{ width: "220px" }}

          ></Column>

          <Column
            field="amount"
            header="Amount"
            sortable
            style={{ width: "200px" }}
          ></Column>
          <Column
            field="currencyCode"
            header="Currency"
            sortable

          ></Column>
          <Column
            field="usdEquivalent"
            header="USD"
            sortable

          ></Column>
          {/* <Column
            field="sectorName"
            header="Sector"
            sortable

          ></Column> */}
          <Column
            field="statusName"
            header="Status"
            sortable
            body={statusDate}
          ></Column>
          <Column
            field="createdDate"
            header="Applicant Received Date"
            sortable
            body={receivedDate} 
          ></Column>
          <Column
            field="submittedDate"
            header="Applicant Submitted Date"
            sortable
            style={{ width: "140px" }}
            body={submittedDate}
          ></Column>
          {/* <Column
              field=""
              header="Bank Record Officer Received Date"
              sortable
             
            ></Column>
             <Column
              field=""
              header="Bank Record Officer Submited Date"
              sortable
             
            ></Column> */}

        </DataTable>
      </div>

    </>
  );
};

export default INSAdvanceSearchTable;
