import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "../components/DashboardLayout";
import { Helmet } from "react-helmet";
import axios from "axios";
import { APIURL, ImageAPI } from "../constant";
import UpdatePopupMessage from "../components/UpdatePopupMessage";
import { toast } from "react-toastify";
import { Storage } from "../login/Storagesetting";
import "cropperjs/dist/cropper.css";

const Changepassword = () => {
  const navigate = useNavigate();
  const menuname = sessionStorage.getItem("menuname");
  const submenuname = sessionStorage.getItem("submenuname");
  const UserID = sessionStorage.getItem("userID");
  const heading = "Profile Updated Successfully!";
  const para = "Profile updated successfully!";

  const MenunameLocal = Storage.getItem("menuname");
  const [loading, setLoading] = useState(false);
  const [Checkpass, setCheckpass] = useState(false);
  const [Checkpassold, setCheckpassold] = useState(false);
  const [Checkpasscnf, setCheckpasscnf] = useState(false);
  const [updatepopup, setupdatepopup] = useState(false);
  const [errors, seterrors] = useState({});

  const [changePassValue, setChangePassValue] = useState({
    oldPass: "",
    newPass: "",
    cnfPass: "",
  });

  const changeHandelForm = (e) => {
    seterrors({});
    const name = e.target.name;
    const value = e.target.value;

    const passwordValue = e.target.value.trim();
    setChangePassValue((prevState) => ({
      ...prevState,
      [name]: passwordValue,
    }));
  };

  const HandleSubmit = async () => {
    if (Validation()) {
      axios
        .post(APIURL + "User/ChangePassword", {
          UserID: UserID.replace(/"/g, ""),
          Password: changePassValue.cnfPass,
          OldPassword: changePassValue?.oldPass,
        })
        .then((res) => {
          if (res.data.responseCode === "200") {
            setupdatepopup(true);
            setChangePassValue({
              oldPass: "",
              newPass: "",
              cnfPass: "",
            });
          } else {
            toast.error(res.data.responseMessage);
          }
        })
        .catch((err) => {
          toast.error(err, {
            autoClose: 1000,
          });
        });
    }
  };

  const Validation = () => {
    let valid = true;
    const newErrors = {};
    const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const numericRegex = /\d/;

    if (changePassValue?.oldPass === "") {
      newErrors.oldPass = "Old password is required";
      valid = false;
    }
    if (changePassValue?.newPass === "") {
      newErrors.newPass = "Password is required";
      valid = false;
    } else if (changePassValue?.newPass.length < 8) {
      newErrors.newPass = "Password must be at least 8 characters long";
      valid = false;
    } else if (!specialCharacterRegex.test(changePassValue?.newPass)) {
      newErrors.newPass =
        "Password must contain at least one special character";
      valid = false;
    } else if (!numericRegex.test(changePassValue?.newPass)) {
      newErrors.newPass = "Password must contain at least one numeric digit";
      valid = false;
    }
    if (changePassValue?.cnfPass === "") {
      newErrors.cnfPass = "Confirm password is required";
      valid = false;
    } else if (changePassValue.newPass !== changePassValue?.cnfPass) {
      newErrors.cnfPass = "Password did not match";
      valid = false;
    }
    if (
      changePassValue.newPass == changePassValue?.oldPass &&
      (changePassValue?.oldPass != "" ||
        changePassValue?.newPass != "" ||
        changePassValue?.cnfPass != "")
    ) {
      toast.error("Same as old password not allowed");
      newErrors.old = "Password did not match";
      valid = false;
    }
    seterrors(newErrors);
    return valid;
  };

  const closePopupHandle = () => {
    Storage.clear();
    sessionStorage.clear();
    navigate("/");
  };

  return (
    <>
      <Helmet>
        <title>
          {menuname ? menuname : "Export"}{" "}
          {submenuname ? submenuname : "Change Passowrd"}
        </title>{" "}
      </Helmet>
      <DashboardLayout>
        <section className="section dashboard">
          <div className="row">
            <div className="col-md-12">
              <div className="datatable">
                <h4 className="section_top_heading mb-4">CHANGE PASSWORD</h4>
                {loading === true ? (
                  <label className="outerloader2">
                    <span className="loader"></span>
                    <span className="loaderwait">Please Wait...</span>
                  </label>
                ) : (
                  <div
                    style={{
                      maxWidth: "1024px",
                      boxShadow: "inset rgb(0 0 0 / 23%) 0px -1px 4px",
                      padding: "25px",
                      marginTop: "10px",
                      background: "#eee0",
                    }}
                    className="mb-3"
                  >
                    <form>
                      <div className="inner_form_new ">
                        <label className="controlform">Old Password</label>
                        <div className="form-bx">
                          <label>
                            <input
                              type={Checkpassold === true ? "text" : "password"}
                              placeholder="Old Password"
                              name="oldPass"
                              onChange={(e) => changeHandelForm(e)}
                              value={changePassValue?.oldPass}
                            />
                            {Checkpassold ? (
                              <i
                                className="bi bi-eye passwordcheck"
                                onClick={() => {
                                  setCheckpassold(!Checkpassold);
                                }}
                              ></i>
                            ) : (
                              <i
                                className="bi bi-eye-slash passwordcheck"
                                onClick={() => {
                                  setCheckpassold(!Checkpassold);
                                }}
                              ></i>
                            )}
                            <span className="sspan"></span>
                          </label>
                          {changePassValue?.oldPass == "" ? (
                            errors?.oldPass ? (
                              <small className="errormsg2">
                                {errors?.oldPass}
                              </small>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="inner_form_new ">
                        <label className="controlform">New Password</label>
                        <div className="form-bx">
                          <label>
                            <input
                              type={Checkpass === true ? "text" : "password"}
                              placeholder="New Password"
                              name="newPass"
                              onChange={(e) => changeHandelForm(e)}
                              value={changePassValue?.newPass}
                            />
                            {Checkpass ? (
                              <i
                                className="bi bi-eye passwordcheck"
                                onClick={() => {
                                  setCheckpass(!Checkpass);
                                }}
                              ></i>
                            ) : (
                              <i
                                className="bi bi-eye-slash passwordcheck"
                                onClick={() => {
                                  setCheckpass(!Checkpass);
                                }}
                              ></i>
                            )}
                            <span className="sspan"></span>
                          </label>
                          {errors?.newPass ? (
                            <small className="errormsg2">
                              {errors?.newPass}
                            </small>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="inner_form_new ">
                        <label className="controlform">
                          Confirm New Password
                        </label>
                        <div className="form-bx">
                          <label>
                            <input
                              type={Checkpasscnf == true ? "text" : "password"}
                              placeholder="Confirm New Password"
                              name="cnfPass"
                              onChange={(e) => changeHandelForm(e)}
                              value={changePassValue?.cnfPass}
                            />

                            {Checkpasscnf ? (
                              <i
                                className="bi bi-eye passwordcheck"
                                onClick={() => {
                                  setCheckpasscnf(!Checkpasscnf);
                                }}
                              ></i>
                            ) : (
                              <i
                                className="bi bi-eye-slash passwordcheck"
                                onClick={() => {
                                  setCheckpasscnf(!Checkpasscnf);
                                }}
                              ></i>
                            )}

                            <span className="sspan"></span>
                          </label>
                          {errors?.cnfPass ? (
                            <small className="errormsg2">
                              {errors?.cnfPass}
                            </small>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="form-bx mt-2 mb-2">
                        <div
                          className="form-footer"
                          style={{ justifyContent: "flex-end" }}
                        >
                          <button
                            type="button"
                            onClick={(e) => {
                              HandleSubmit(e);
                            }}
                            className="login"
                          >
                            Save Change
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        {updatepopup == true ? (
          <UpdatePopupMessage
            heading={heading}
            para={para}
            applicationNumber=""
            closePopupHandle={closePopupHandle}
          ></UpdatePopupMessage>
        ) : (
          ""
        )}
      </DashboardLayout>
    </>
  );
};

export default Changepassword;
