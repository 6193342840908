import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DashboardLayout from "../components/DashboardLayout";
import { Helmet } from "react-helmet";
import { Storage } from "../login/Storagesetting";
import { APIURL } from "../constant";
import axios from "axios";
import ExportAllRecordsTable from "../tables/ExportAllRecordsTable";

const ExportAllRecords = () => {
  const [data, setData] = useState([]);

  const menuname = sessionStorage.getItem("menuname");
  const submenuname = sessionStorage.getItem("submenuname");

  const RoleId = Storage.getItem("roleIDs");
  const useId = Storage.getItem("userID");

  // ----- Start Code For Geting Table List Data
  const handleData = async () => {
    await axios
      .post(APIURL + "ExportApplication/GetActingDashboardData", {
        DepartmentID: "2",
        UserID: useId.replace(/"/g, ""),
        RoleID: RoleId,
      })
      .then((res) => {
        if (res.data.responseCode === "200") {
          setData(res.data.responseData);
        } else {
          setData([]);
        }
      });
  };

  useEffect(() => {
    handleData();
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {menuname ? menuname : "Export"}{" "}
          {submenuname ? submenuname : "Dashboard"}
        </title>{" "}
      </Helmet>
      <DashboardLayout>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/BankADLADashboard">Exports</Link>
            </li>
            <li className="breadcrumb-item active">Dashboard</li>
          </ol>
        </nav>

        <section className="section dashboard">
          <div className="row">
            <div className="col-md-12">
              <div className="datatable">
                <h4 className="section_top_heading">
                  ALL RECORDS                                  
                </h4>
                <ExportAllRecordsTable />
              </div>
            </div>
          </div>
        </section>
      </DashboardLayout>
    </>
  );
};

export default ExportAllRecords;
